import {useState} from "react";

const Customers = () => {

    const [activeItem , setActiveItem] = useState(0)
  return <div className="customers">
      <div className="clients_bg">
          <div className="container">
              <div className="row">
                  <div className="col-sm-12">
                      <div className="titlepage text_align_center">
                          <h2>Hva sier kunder</h2>
                      </div>
                  </div>
              </div>
          </div>
      </div>
      {/* start slider section */}
      <div
          id="myCarousel"
          className="carousel slide clients_banner"
          data-ride="carousel"
      >
          <ol className="carousel-indicators">
              <li data-target="#myCarousel" data-slide-to={0} className="active" />
              <li data-target="#myCarousel" data-slide-to={1} />
              <li data-target="#myCarousel" data-slide-to={2} />
          </ol>
          <div className="carousel-inner">
              <div className={activeItem === 0 ? "carousel-item active"  : "carousel-item"}>
                  <div className="container">
                      <div className="carousel-caption relative">
                          <div className="row d_flex">
                              <div className="col-md-2 col-sm-8">
                                  <div className="pro_file">
                                      <i>
                                          <img src="images/test2.png" alt="#" />
                                      </i>
                                      <h4>Elisabeth M.</h4>
                                  </div>
                              </div>
                              <div className="col-md-10">
                                  <div className="test_box text_align_left">
                                      <p>
                                          Jeg besøkte Tulipe SPA for første gang forrige måned, og det var en uforglemmelig opplevelse. Stemningen er utrolig rolig, og personalet er imøtekommende og profesjonelle. Dypvevsmassasjen jeg fikk var den beste jeg noen gang har hatt, og smeltet bort alt stresset og spenningen. Jeg dro derfra og følte meg fullstendig forynget. Anbefaler på det sterkeste!
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className={activeItem === 1 ? "carousel-item active"  : "carousel-item"}>
                  <div className="container">
                      <div className="carousel-caption relative">
                          <div className="row d_flex">
                              <div className="col-md-2 col-sm-8">
                                  <div className="pro_file">
                                      <i>
                                          <img src="images/test2.png" alt="#" />
                                      </i>
                                      <h4>Emily R.</h4>
                                  </div>
                              </div>
                              <div className="col-md-10">
                                  <div className="test_box text_align_left">
                                      <p>
                                          Tulipe SPA overgikk alle mine forventninger. Fra det øyeblikket jeg gikk inn, kjente jeg en følelse av ro skylle over meg. Ansiktsbehandlingen ble skreddersydd etter hudens behov, og resultatene var forbløffende. Huden min har aldri sett bedre ut! Oppmerksomheten på detaljer og genuin omsorg fra terapeutene gjør dette spaet til en enestående. Jeg kan ikke vente med å komme tilbake.
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
              <div className={activeItem === 2 ? "carousel-item active"  : "carousel-item"}>
                  <div className="container">
                      <div className="carousel-caption relative">
                          <div className="row d_flex">
                              <div className="col-md-2 col-sm-8">
                                  <div className="pro_file">
                                      <i>
                                          <img src="images/test2.png" alt="#" />
                                      </i>
                                      <h4>Michael .R</h4>
                                  </div>
                              </div>
                              <div className="col-md-10">
                                  <div className="test_box text_align_left">
                                      <p>
                                          Hvert besøk til Tulipe SPA er en fantastisk flukt fra mitt travle liv. Miljøet er vakkert og fredelig, og behandlingene er førsteklasses. Jeg prøvde nylig deres hot stone massasje, og det var utrolig beroligende. Teamet her forstår virkelig viktigheten av velvære og sørger for at du drar avgårde med det beste. Takk for at du skapte et så spesielt sted!
                                      </p>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <a
              className="carousel-control-prev"
              href="#myCarousel"
              role="button"
              data-slide="prev"

              onClick={() => {
                  console.log(activeItem)
                  if(activeItem <= 0) {
                      setActiveItem(2)
                      return
                  }
                  setActiveItem(activeItem-1)
              }}
          >
              <i className="fa fa-angle-left" aria-hidden="true" />
              <span className="sr-only">Previous</span>
          </a>
          <a
              className="carousel-control-next"
              href="#myCarousel"
              role="button"
              data-slide="next"

              onClick={() => {
                  if(activeItem === 2) {
                      setActiveItem(0)
                      return
                  }
                  setActiveItem(activeItem+1)
              }}
          >
              <i className="fa fa-angle-right" aria-hidden="true" />
              <span className="sr-only">Neste</span>
          </a>
      </div>
  </div>

}
export default Customers
