const Pricing = () => {
  return <div className="priceing">
      <div className="container">
          <div className="row">
              <div className="col-md-12">
                  <div className="titlepage text_align_center">
                      <h2>Våre priser </h2>
                      <p>

                      </p>
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-md-10 offset-md-1">
                  <div className="row">
                      <div className="col-md-6">
                          <div className="our_priceing text_align_center">
                              <div className="our_pri">
                                  <h3>Vanlig pris</h3>
                                  <span>
                  $<strong>50</strong>
                </span>
                                  <p>
                                      Massasjeterapier <br />
                                      eller ansiktsbehandlinger<br />
                                      eller kroppsbehandlinger

                                  </p>
                              </div>

                          </div>
                      </div>
                      <div className="col-md-6">
                          <div className="our_priceing text_align_center">
                              <div className="our_pri">
                                  <h3>Spesialpris</h3>
                                  <span>
                  $<strong>100</strong>
                </span>
                                  <p>
                                      Kroppsbehandlinger
                                      <br />
                                      og ansiktsbehandlinger
                                      <br />
                                      og kroppsbehandlinger

                                  </p>
                              </div>

                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

}
export default Pricing
