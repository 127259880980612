import React from 'react';
import logo from './logo.svg';
import './App.css';
import Home from "./pages/Home";


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Contact from "./pages/Contact";
import Service from "./pages/Service";
import BlogsPage from "./pages/BlogsPage";
import About from "./pages/About";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },

  {
    path: "/contact",
    element: <Contact />,
  },

  {
    path: "/service",
    element: <Service />,
  }
  ,

  {
    path: "/blogs",
    element: <BlogsPage />,
  }
  ,

  {
    path: "/about",
    element: <About />,
  }
]);
function App() {
  return (
      <RouterProvider router={router} />
  );
}

export default App;
