import DefaultLayout from "../components/DefaultLayout";
import MakeAppointement from "../components/MakeAppointement";
import Services from "../components/Services";
import Pricing from "../components/Pricing";
import Blogs from "../components/Blogs";
import AboutShort from "../components/AboutShort";
import Customers from "../components/Customers";
import AboutLong from "../components/AboutLong";

const About = () => {
    return <DefaultLayout sliderVisible={false}>



        <AboutLong/>



    </DefaultLayout>
}

export default About
