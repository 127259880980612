import Header from "./Header";
import {ReactNode} from "react";
import MainSlider from "./MainSlider";
import Footer from "./Footer";
type Props = {
    children: ReactNode,
    sliderVisible?: boolean
}
const DefaultLayout = ({children , sliderVisible}: Props) => {
    return <div className="full_bg">
        <Header />
        {sliderVisible === true && <MainSlider/>}

        {children}

        <Footer />

    </div>
}
export default DefaultLayout
