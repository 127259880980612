import DefaultLayout from "../components/DefaultLayout";
import MakeAppointement from "../components/MakeAppointement";
import Services from "../components/Services";
import Pricing from "../components/Pricing";
import Blogs from "../components/Blogs";
import AboutShort from "../components/AboutShort";
import Customers from "../components/Customers";

const Home = () => {
    return <DefaultLayout sliderVisible={true}>

        <MakeAppointement/>

        <Services/>

        <Pricing/>


        <AboutShort/>

        <Customers/>

    </DefaultLayout>
}

export default Home
