const MainSlider = () => {
  return <div className="slider_main">
      {/* carousel code */}
      <div id="banner1" className="carousel slide">
          <ol className="carousel-indicators">
              <li data-target="#banner1" data-slide-to={0} className="active" />
              <li data-target="#banner1" data-slide-to={1} />
              <li data-target="#banner1" data-slide-to={2} />
          </ol>
          <div className="carousel-inner">
              {/* first slide */}
              <div className="carousel-item active">
                  <div className="container">
                      <div className="carousel-caption relative">
                          <div className="row d_flex">
                              <div className="col-md-5">
                                  <div className="creative">
                                      <h1>
                                          Tulipe SPA
                                      </h1>
                                      <p>
                                          Akkurat nå kan du få massasje fra en ekspert + kroppsanalyse + behandlingsplan for kun 545kr!
                                      </p>
                                      <a className="read_more" href="/contact">
                                          Kontakt oss
                                      </a>

                                  </div>
                              </div>
                              <div className="col-md-7">
                                  <div className="row mar_right">
                                      <div className="col-md-6">
                                          <div className="agency">
                                              <figure>
                                                  <img src="images/img1.png" alt="#" />
                                              </figure>
                                              <div className="play_icon">
                                                  <a className="play-btn" href="javascript:void(0)">
                                                      <img src="images/play_icon.png" />
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="col-md-6">
                                          <div className="agency">
                                              <figure>
                                                  <img src="images/img2.png" alt="#" />
                                              </figure>
                                              <div className="play_icon">
                                                  <a className="play-btn" href="javascript:void(0)">
                                                      <img src="images/play_icon.png" />
                                                  </a>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>

          </div>
          {/* controls */}

      </div>
  </div>

}

export default MainSlider
