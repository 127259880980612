import {useState} from "react";

const Header = () => {



    const [menuOpen , setMenuOpen] = useState(false)
  return <header className="header-area">
      <div className="container">
          <div className="row d_flex">
              <div className=" col-md-3 col-sm-3">
                  <div className="logo">
                      <img src={"images/logo.png"} width={40} height={40} style={{marginTop: "1rem" , marginBottom: "1rem"}}/>
                  </div>
              </div>
              <div className="col-md-9 col-sm-9">
                  <div className="navbar-area">
                      <nav className="site-navbar">
                          <ul className={menuOpen ? "open" : ""}>
                              <li>
                                  <a className={window.location.href === "/" ? "active" : ""} href="/">
                                      Home
                                  </a>
                              </li>
                              <li>
                                  <a href="about" className={window.location.href.indexOf("/about") != -1 ? "active" : ""}>Om</a>
                              </li>
                              <li>
                                  <a href="service" className={window.location.href.indexOf("/service") != -1  ? "active" : ""}>Service</a>
                              </li>
                              {false && <li>
                                  <a href="blogs"
                                     className={window.location.href.indexOf("/blogs") != -1 ? "active" : ""}>Blog</a>
                              </li>}
                              <li>
                                  <a href="contact" className={window.location.href.indexOf("/contact") != -1 ? "active" : ""}>Kontakt oss</a>
                              </li>
                              {false && <><li className="d_none">
                                  <a href="Javascript:void(0)">
                                      <i className="fa fa-user" aria-hidden="true"/>
                                  </a>
                              </li>
                                  <li className="d_none">
                                  <a href="Javascript:void(0)">
                                  <i className="fa fa-search" aria-hidden="true" />
                                  </a>
                                  </li></>}
                          </ul>
                          <button className={menuOpen ? "nav-toggler toggler-open" : "nav-toggler"} onClick={() => setMenuOpen(!menuOpen)}>
                              <span />
                          </button>
                      </nav>
                  </div>
              </div>
          </div>
      </div>
  </header>
}
export default Header
