const Services = () => {
  return <div className="services">
      <div className="container">
          <div className="row">
              <div className="col-md-12">
                  <div className="titlepage text_align_center ">
                      <h2>Våre massasjetjenester</h2>
                  </div>
              </div>
          </div>
          <div className="row">
              <div className="col-md-4">
                  <div id="ho_shad" className="services_box text_align_left">
                      <h3>Stressreduksjon</h3>
                      <figure>
                          <img src="images/service1.jpg" alt="#" />
                      </figure>
                      <p>
                          En av de mest kjente fordelene med massasje er dens evne til å redusere stress og fremme avslapning. Massasje kan bidra til å senke nivåene av stresshormoner i kroppen, noe som fører til en følelse av ro og
                      </p>

                  </div>
              </div>
              <div className="col-md-4">
                  <div id="ho_shad" className="services_box text_align_left">
                      <h3>Smertelindring</h3>
                      <figure>
                          <img src="images/service2.jpg" alt="#" />
                      </figure>
                      <p>
                          Massasjeterapi kan gi lindring fra ulike typer smerte, inkludert muskelsmerter, leddsmerter og kroniske tilstander som leddgikt. Det kan bidra til å redusere muskelspenninger og forbedre sirkulasjonen, noe som kan lindre smerte.
                      </p>

                  </div>
              </div>
              <div className="col-md-4">
                  <div id="ho_shad" className="services_box text_align_left">
                      <h3>Muskelavslapping</h3>
                      <figure>
                          <img src="images/service3.jpg" alt="#" />
                      </figure>
                      <p>
                          Massasje kan hjelpe til med å slappe av anspente muskler og frigjøre muskelknuter. Det brukes ofte som en form for terapi for muskelskader og kroniske muskelspenninger.
                      </p>

                  </div>
              </div>
          </div>
      </div>
  </div>

}
export default Services
