const AboutLong = () => {
  return <div className="about">
      <div className="container_width">
          <div className="row d_flex grig">
              <div className="col-md-6">
                  <div className="about_img">
                      <figure>
                          <img src="images/about_img.jpg" alt="#" />
                      </figure>
                  </div>
              </div>
              <div className="col-md-6 order">
                  <div className="titlepage text_align_left">
                      <h2>About Our Center</h2>
                      <>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              <strong>Velkommen til Tulipe SPA</strong>
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              Hos Tulipe SPA tror vi på kraften til avslapning og foryngelse.
                              Spaet vårt ligger i hjertet av [City], og er et fristed hvor du kan flykte
                              stresset i hverdagen og fordyp deg i en verden av ro
                              og velvære.
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              <strong>Vårt oppdrag</strong>
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              Vårt oppdrag er å gi en helhetlig tilnærming til helse og velvære,
                              å kombinere eldgamle teknikker med moderne praksis for å skape en unik og
                              personlig spa-opplevelse. Vi er dedikerte til å hjelpe deg med å oppnå en stat
                              full avslapning og balanse.
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              <strong>Vår historie</strong>
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              Etablert i [År], har Tulipe SPA vokst til å bli et elsket reisemål
                              for de som søker fred og foryngelse. Reisen vår begynte med lidenskap
                              for velvære og et ønske om å skape et rom hvor kundene kan oppleve
                              det høyeste nivået av omsorg og komfort.
                          </p>

                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              <strong>Våre fasiliteter</strong>
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              Våre toppmoderne fasiliteter er designet med tanke på din komfort. Fra
                              vår rolige lobby til våre luksuriøse behandlingsrom, hver detalj er laget
                              for å gi en spaopplevelse uten sidestykke. Nyt våre hydroterapibassenger,
                              badstuer og private behandlingsområder, alt i et rolig og vakkert
                              miljø.
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              <strong>Our Team</strong>
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              Our team of highly trained and experienced therapists and aestheticians are
                              committed to providing exceptional service. Each member of our staff is
                              passionate about wellness and dedicated to ensuring that you leave feeling
                              refreshed and rejuvenated.
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              <strong>Vår forpliktelse til deg</strong>
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              Hos Tulipe SPA er ditt velvære vår høyeste prioritet. Vi streber etter å skape en
                              miljø hvor du kan slappe av, slappe av og forynge. Enten du er det
                              På jakt etter en rask flukt eller en omfattende velværereise, er vi her
                              for å hjelpe deg med å nå dine mål.
                          </p>
                          <p style={{ textAlign: "start", color: "rgb(0, 0, 0)" }}>
                              <strong>
                                  Takk for at du valgte Tulipe SPA. Vi ser frem til å ønske deg velkommen snart.
                              </strong>
                          </p>
                      </>

                  </div>
              </div>
          </div>
      </div>
  </div>

}
export default AboutLong
