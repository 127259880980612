import DefaultLayout from "../components/DefaultLayout";
import MakeAppointement from "../components/MakeAppointement";
import Services from "../components/Services";
import Pricing from "../components/Pricing";
import Blogs from "../components/Blogs";
import AboutShort from "../components/AboutShort";
import Customers from "../components/Customers";

const Service = () => {
    return <DefaultLayout sliderVisible={false}>



        <Services/>

    </DefaultLayout>
}

export default Service
