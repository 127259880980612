import DefaultLayout from "../components/DefaultLayout";
import MakeAppointement from "../components/MakeAppointement";

const Contact = () => {
    return <DefaultLayout sliderVisible={false}>
        <MakeAppointement/>
    </DefaultLayout>
}

export default Contact
