const AboutShort = () => {
  return <div className="about">
      <div className="container_width">
          <div className="row d_flex grig">
              <div className="col-md-6">
                  <div className="about_img">
                      <figure>
                          <img src="images/about_img.jpg" alt="#" />
                      </figure>
                  </div>
              </div>
              <div className="col-md-6 order">
                  <div className="titlepage text_align_left">
                      <h2>Om senteret vårt</h2>
                      <p>
                          Velkommen til Massasje Helse og Skjønnhet, din oase for avslapning og velvære i Kristiansand. Her tilbyr vi en rekke profesjonelle massasje-behandlinger designet for å fremme både kropp og sjel.
                          Vårt team ledes av Amanda, en erfaren og lidenskapelig massasjeterapeut som brenner for å hjelpe mennesker med å finne balanse i hverdagen. Med flere års erfaring innen massasje og skjønnhet har Amanda skapt et sted hvor hver besøkende kan føle seg ivaretatt og fornyet.
                      </p>
                      <a className="read_more" href="/about">
                          Les mer
                      </a>
                  </div>
              </div>
          </div>
      </div>
  </div>

}
export default AboutShort
