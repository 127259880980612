import DefaultLayout from "../components/DefaultLayout";
import MakeAppointement from "../components/MakeAppointement";
import Services from "../components/Services";
import Pricing from "../components/Pricing";
import Blogs from "../components/Blogs";
import AboutShort from "../components/AboutShort";
import Customers from "../components/Customers";

const BlogsPage = () => {
    return <DefaultLayout sliderVisible={false}>

        <Blogs/>

    </DefaultLayout>
}

export default BlogsPage
