const Footer = () => {
  return <footer>
      <div className="footer">
          <div className="container">
              <div className="row">
                  <div className="col-md-6">
                      <div className="row">
                          <div className="col-lg-12 col-md-12">
                              <div className="hedingh3 text_align_left">
                                  <h3>Om</h3>
                                  <p>
                                      Oppdag ro og fornyelse: Ditt ultimate tilfluktssted for sinn, kropp og ånd
                                  </p>
                              </div>
                          </div>

                      </div>
                  </div>
                  <div className="col-md-3"></div>
                  <div className="col-md-3">
                      <div className="row">
                          <div className="col-lg-6 col-md-12">
                              <div className="hedingh3 text_align_left">
                                  <h3>Menu</h3>
                                  <ul className="menu_footer">
                                      <li>
                                          <a href="/">Home</a>
                                      </li>
                                      <li>
                                          <a href="about">About</a>
                                      </li>
                                      <li>
                                          <a href="service">Service</a>
                                      </li>
                                      <li>
                                          <a href="blog">Blog</a>
                                      </li>
                                      <li>
                                          <a href="contact">Kontakt oss</a>
                                      </li>
                                  </ul>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div className="copyright">
              <div className="container">
                  <div className="row">
                      <div className="col-md-12">
                          <div className="follow text_align_center">
                              <h3>Follow Us</h3>
                              <ul className="social_icon ">
                                  <li>
                                      <a href="Javascript:void(0)">
                                          <i className="fa fa-facebook" aria-hidden="true" />
                                      </a>
                                  </li>
                                  <li>
                                      <a href="Javascript:void(0)">
                                          <i className="fa fa-twitter" aria-hidden="true" />
                                      </a>
                                  </li>
                                  <li>
                                      <a href="Javascript:void(0)">
                                          <i className="fa fa-linkedin" aria-hidden="true" />
                                      </a>
                                  </li>
                                  <li>
                                      <a href="Javascript:void(0)">
                                          <i className="fa fa-instagram" aria-hidden="true" />
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-md-12">
                          <p>
                              © 2024 All Rights Reserved. Design by{" "}
                              <a href="https://branholo.com/" style={{color:"green"}}> Branholo</a>
                          </p>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </footer>

}

export default Footer
